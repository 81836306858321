<!--
  管理员页面
    + 超级管理员有权限进行添加、编辑、禁用等操作
    + 管理员只有对客服有操作
 -->
<template>
  <div class="card">
    <div class="card_search">
      <el-button
        v-if="role === globalVar.ADMIN_ROLE_0 || role === globalVar.ADMIN_ROLE_1"
        type="primary"
        size="small"
        @click="onAdd"
      >
        新增后台账号
      </el-button>
      <el-input
        placeholder="请输入用户名搜索"
        v-model="keywords"
        size="small"
        style="width:340px;margin-left:10px;"
        @keyup.native.enter="onSearch"
      />
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="headerCellStyle"
      @row-click="onDetail"
    >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
      >
      </el-table-column>
      <el-table-column
        prop="gender"
        label="性别"
      >
        <template slot-scope="scope">
          {{ scope.row.gender === 0 ? '未知' : scope.row.gender === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="role"
        label="权限"
      >
        <template slot-scope="scope">
          {{ scope.row.role === globalVar.ADMIN_ROLE_2 ? '客服' : '管理员' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="账号"
      >
      </el-table-column>
      <el-table-column
        width="100px"
        label="操作"
      >
        <template slot-scope='scoped'>
          <div v-if="(role === globalVar.ADMIN_ROLE_0) || (role === globalVar.ADMIN_ROLE_1 && scoped.row.role === globalVar.ADMIN_ROLE_2)">
            <span
              v-if="scoped.row.enabled === globalVar.ADMIN_ENABLED_STATUS_1"
              class="text_color_danger tw-cursor-pointer tw-mr-10px"
              @click.stop="changeAccountStatus(scoped.row, globalVar.ADMIN_ENABLED_STATUS_0)"
            >
              禁用
            </span>
            <span
              v-else
              class="text_color_active tw-cursor-pointer"
              @click.stop="changeAccountStatus(scoped.row, globalVar.ADMIN_ENABLED_STATUS_1)"
            >
              启用
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="card_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        layout="prev, pager, next, jumper"
        @current-change="page => getTableData(page, keywords)"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      keywords: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    globalVar () {
      return globalVar
    }
  },
  created () {
    this.onSearch()
  },
  methods: {
    onSearch () {
      this.getTableData(1, this.keywords)
    },
    // 进入详情
    onDetail (row) {
      if (this.role === this.globalVar.ADMIN_ROLE_0 || (this.role === this.globalVar.ADMIN_ROLE_1 && row.role === this.globalVar.ADMIN_ROLE_2)) {
        const id = row.id ? row.id : ''
        const { href } = this.$router.resolve({
          path: `/account-manage/admin-manage/detail/${id}`
        })
        window.open(href, '_blank')
      }
    },
    // 添加后台账号
    onAdd () {
      const { href } = this.$router.resolve({
        path: '/account-manage/admin-manage/detail'
      })
      window.open(href, '_blank')
    },
    /**
     * 搜索表格数据
     * 0 - 获取管理员 + 客服；1 - 获取管理员；2 - 获取客服
     * 超管永远不会返回
     */
    getTableData (pageNum, keywords) {
      this.loading = true
      api.getAccountList(0, {
        pageNum,
        pageSize: this.pagination.pageSize,
        keywords
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 禁用账号
     */
    changeAccountStatus (item, status) {
      const msg = {
        title: '',
        message: ''
      }
      if (status) {
        msg.title = '启用账号'
        msg.message = '确定要启用此账号？'
      } else {
        msg.title = '禁用账号'
        msg.message = '确定要禁用此账号？'
      }
      this.$msgbox({
        title: msg.title,
        message: msg.message,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.toggleAccountEnabled(item, instance, done, status)
          } else {
            done()
          }
        }
      })
    },

    /**
     * 启用禁用
     */
    toggleAccountEnabled (item, instance, done, status) {
      instance.confirmButtonLoading = true
      api.toggleAccountEnabled(item.id, {
        enabled: status
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getTableData(this.pagination.pageNum, this.keywords)
        } else {
          this.$message.error(res.data.message)
        }
        done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        instance.confirmButtonLoading = false
      })
    },

    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 980px;
  padding: 30px 40px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_search {
    margin-bottom: 20px;
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
